import '~/assets/scss/404.scss'

import getSwiperSlider from '~/js/modules/slider';

window.addEventListener(`DOMContentLoaded`, () => {
    const productsSliders = document.querySelectorAll(`.js-products-slider`);

    const productsSwiperParams = {
        slidesPerView: `auto`,
        loop: false,
        autoplay: false,
        spaceBetween: 48,
    };

    if (productsSliders.length) {
        productsSliders.forEach((productsSlider) => {
            getSwiperSlider(productsSlider, productsSwiperParams);
        });
    }
});
